"use strict";

/**
 * Extends object with other objects
 *
 * @example const newObject = deepExtend({}, objA, objB);
 *
 * @param {object} out - Base of extended object
 * @return {object} - final extended object
 */
function deepExtend(out) {
  const extOut = out || {};
  const getNewValue = (prop, key) => {
    if (Array.isArray(prop)) return prop.slice(0);
    if ("object" === typeof prop) return deepExtend(out[key], prop);
    return prop;
  };

  for (let i = 1; i < arguments.length; i++) {
    const obj = arguments[i];
    if (!obj) continue;
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        extOut[key] = getNewValue(obj[key], key);
      }
    }
  }

  return extOut;
}

export default deepExtend;
