"use strict";

const MAXIMUM_VIEWPORT_STOP = 9999999;

class Breakpoint {
  /**
   * Interface class for breakpoint objects
   *
   * @param {String} name
   * @param {Number} value
   */
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }

  /**
   * @return {String}
   */
  toString() { return this.name; }

  /**
   * @return {Number}
   */
  toNumber() { return this.value; }
}

class Breakpoints {
  /**
   * @param {Object} bpObj
   */
  constructor(bpObj) { this.values = bpObj; }

  /**
   * @return {Breakpoints}
   */
  init() {
    this.bpArr = this.buildWorkingArray();
    window.breakpoints = this; // expose Breakpoints instance global
    return this;
  }

  /**
   * Get an sorted array of Breakpoint instances
   *
   * @return {Array<Breakpoint>}
   */
  buildWorkingArray() {
    const entries = Object.entries(this.values);
    const workingArray = [];

    entries.forEach((entry) => workingArray.push(new Breakpoint(entry[0], entry[1])));
    return workingArray.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
  }

  /**
   * Get Breakpoint instance from working Array by name
   *
   * @param {String} name
   * @return {Breakpoint}
   */
  getBreakpointByName(name) {
    const bpItem = this.bpArr.find((item) => item.name === name);
    if (!bpItem) throw new ReferenceError(`A breakpoint named ${name} is not defined.`);
    return bpItem;
  }

  /**
   * Check by name if breakpoint is active
   *
   * @param {String} name
   * @param {Breakpoint|undefined} breakpointItem
   * @return {Boolean}
   */
  isBreakpoint(name, breakpointItem = undefined) {
    const bpItem = undefined === breakpointItem ? this.getBreakpointByName(name) : breakpointItem;
    const stopItem = this.bpArr[this.bpArr.indexOf(bpItem) + 1];

    const start = bpItem.value;
    const stop = stopItem ? stopItem.value : MAXIMUM_VIEWPORT_STOP;
    const viewWidth = window.innerWidth;

    return (viewWidth >= start && viewWidth < stop);
  }

  /**
   * Check if viewport is less then breakpoint by name
   *
   * @param {String} name
   * @param {Breakpoint|undefined} breakpointItem
   * @return {Boolean}
   */
  isBelow(name, breakpointItem = undefined) {
    const bpItem = undefined === breakpointItem ? this.getBreakpointByName(name) : breakpointItem;
    return window.innerWidth < bpItem.value;
  }

  /**
   * Check by name if breakpoint is active or viewport is smaller
   *
   * @param name
   * @return {Boolean}
   */
  isEqualOrBelow(name) {
    const bpItem = this.getBreakpointByName(name);
    return (this.isBreakpoint(name, bpItem) || this.isBelow(name, bpItem));
  }

  /**
   * Check if viewport is more then breakpoint by name
   *
   * @param {String} name
   * @return {Boolean}
   */
  isAbove(name) {
    const bpItem = this.getBreakpointByName(name);
    const nextItem = this.bpArr[this.bpArr.indexOf(bpItem) + 1];
    const nextValue = nextItem ? nextItem.value : MAXIMUM_VIEWPORT_STOP;
    return window.innerWidth >= nextValue;
  }

  /**
   * Check by name if breakpoint is active or viewport is bigger
   *
   * @param {String} name
   * @return {Boolean}
   */
  isEqualOrAbove(name) {
    const bpItem = this.getBreakpointByName(name);
    return window.innerWidth >= bpItem.value;
  }

  /**
   * Get Breakpoint instance by number
   *
   * @param {Number} value
   * @return {Breakpoint|null}
   */
  getBreakpointByValue(value) {
    const reducedSet = this.bpArr.filter((bp) => bp.value <= value).reverse();
    return (0 === reducedSet.length) ? null : reducedSet[0];
  }
}

export default Breakpoints;
export { Breakpoint, Breakpoints };
