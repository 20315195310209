"use strict";

// Import jQuery for global usage
// import "jquery";

// globally install lazysizes
import "lazysizes";

// Import custom JS modules
import applyBreakpointChangeEvent from "./modules/_breakpoint-change.js";
import applyClientDetectionSelectors from "./modules/_client-detection.js";
import exposeHelperToWindow from "./helper/index.js";

import { validateForms } from "./modules/_forms.js";
import dynamicHeader from "./modules/_dynamic-header.js";

// import loadSvgSymbols from "./modules/_load-svg-symbols.js"; // uncomment to load svg sprite
// import applyAnchorScroll from "./modules/_anchor-scroll.js"; // uncomment to initialize anchor scroll

exposeHelperToWindow();
applyClientDetectionSelectors();
applyBreakpointChangeEvent();

validateForms();
dynamicHeader();

// loadSvgSymbols(); // uncomment to load svg sprite
// applyAnchorScroll(); // uncomment to initialize anchor scroll
