/* eslint-disable no-unused-vars */
import BrowserDetect from "../classes/BrowserDetect.js";
import OSDetect from "../classes/OSDetect.js";

const n = navigator;

// Add CSS classes for detected browser
const browser = new BrowserDetect(n).init();
const browserResults = browser.getResults();
const isIE = browserResults.IE;
const isEdge = browserResults.Edge;

// Add CSS classes for operating system/device
const system = new OSDetect(n).init();
const systemResults = system.getResults();

const isMobile = systemResults.mobile; // mobile device = touch

// Manipulate class list of html tag
export default function applyClientDetectionSelectors() {
  const root = document.documentElement;

  // Change CSS classes for javascript detection
  root.classList.remove("no-js");
  root.classList.add("has-js");

  BrowserDetect.addClassNames(browserResults);
  OSDetect.addClassNames(systemResults);

  if (isIE) root.classList.add(`is-msie-${BrowserDetect.getVersionOfIE(n)}`);
  if (isEdge) root.classList.add(`is-msie-${BrowserDetect.getVersionOfEdge(n)}`);
}

// Export variables for usage in other JS modules
export { browser, browserResults, isIE, isEdge, system, systemResults, isMobile };
