"use strict";

import AbstractDetect from "./AbstractDetect.js";

export default class BrowserDetect extends AbstractDetect {
  /**
   * @param {Navigator} navigator
   */
  constructor(navigator) {
    super();
    this.navigator = navigator;
    this.browsers = {
      Chrome: false,
      Firefox: false,
      Safari: false,
      IE: false,
      Edge: false,
      EdgeChromium: false,
      Vivaldi: false,
      OperaMini: false
    };
  }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isChrome(navigator) {
    const ua = navigator.userAgent;
    return (/Chrome/).test(ua) &&
      (/Google Inc/).test(navigator.vendor) &&
      !BrowserDetect.isVivaldi(navigator) &&
      !BrowserDetect.isEdgeChromium(navigator);
  }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isFirefox(navigator) { return -1 < navigator.userAgent.toLowerCase().indexOf("firefox"); }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isSafari(navigator) {
    const ua = navigator.userAgent.toLowerCase();
    return (/safari/).test(ua) && -1 === ua.indexOf("chrome");
  }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isIE(navigator) {
    const ua = navigator.userAgent.toLowerCase();
    return -1 < ua.indexOf("msie ") || -1 < ua.indexOf("trident");
  }

  /**
   * @param {Navigator} navigator
   * @return {boolean}
   */
  static isEdge(navigator) { return (/Edge\//).test(navigator.userAgent); }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isVivaldi(navigator) { return (/vivaldi/).test(navigator.userAgent.toLowerCase()); }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isOperaMini(navigator) { return (/Opera Mini/).test(navigator.userAgent); }

  /**
   * @param {Navigator} navigator
   * @return {Boolean}
   */
  static isEdgeChromium(navigator) { return (/edg\//).test(navigator.userAgent.toLocaleLowerCase()); }

  /**
   * @param {Navigator} navigator
   * @return {Number}
   */
  static getVersionOfIE(navigator) {
    /* eslint-disable no-magic-numbers */
    const ua = navigator.userAgent;
    if (-1 < ua.indexOf("Trident")) return 11;

    const msie = ua.indexOf("MSIE ");
    return parseInt(ua.substr(msie + 5, ua.indexOf(".", msie)));
    /* eslint-enable no-magic-numbers */
  }

  /**
   * @param {Navigator} navigator
   * @return {Number}
   */
  static getVersionOfEdge(navigator) {
    const ua = navigator.userAgent;
    const edge = ua.indexOf("Edge/");

    // eslint-disable-next-line no-magic-numbers
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)));
  }

  /**
   * @return {BrowserDetect}
   */
  init() {
    this.runBrowserTests();
    return this;
  }

  /**
   * @return {BrowserDetect}
   */
  runBrowserTests() {
    const browsers = Object.keys(this.browsers);

    browsers.map((browser) => {
      this.browsers[browser] = BrowserDetect[`is${browser}`].call(null, this.navigator);
    });

    return this;
  }

  /**
   * @return {{
   *   Vivaldi: Boolean,
   *   Safari: Boolean,
   *   Chrome: Boolean,
   *   IE: Boolean,
   *   Edge: Boolean,
   *   EdgeChromium: Boolean
   *   Firefox: Boolean
   * }}
   */
  getResults() { return this.browsers; }
}
