"use strict";

/**
 * Execute a function on document state 'ready'
 *
 * @param {function} fn - function to execute
 */
function onReady(fn) {
  if ("function" === typeof fn) throw new TypeError(`Expected a function but get ${typeof fn}`);
  if ("loading" !== document.readyState) fn();
  if ("loading" === document.readyState) document.addEventListener("DOMContentLoaded", fn);
}

export default onReady;
