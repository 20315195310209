"use strict";

/**
 * Returns correct type values from an elements dataset
 *
 * @param {HTMLElement} element
 * @param {string} dataName
 * @return {*}
 */
function getData(element, dataName) {
  if (!element.dataset) return null;
  if (!Object.prototype.hasOwnProperty.call(element.dataset, dataName)) return null;

  let dataValue = element.dataset[dataName];

  try {
    return JSON.parse(dataValue);
  } catch (e) {
    if ("false" === dataValue) return false;
    if ("true" === dataValue) return true;
    if ("undefined" === dataValue) return undefined;
    if ((/^\d+\.\d+$/).test(dataValue)) return parseFloat(dataValue);
    if ((/^\d+$/).test(dataValue)) return parseInt(dataValue);
    return element.dataset[dataName];
  }
}

export default getData;
