"use strict";

/**
 * Returns boolean type value of HTML attribute
 * If value is not "true" always return false
 *
 * @param {Element} element
 * @param {string} name
 * @return {boolean}
 */
function getBoolAttr(element, name) {
  if (!element.hasAttribute(name)) return false;
  return "true" === element.getAttribute(name);
}

export default getBoolAttr;
