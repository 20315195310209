"use strict";

/**
 * Get a computed style value from an element
 *
 * @param {Element} element
 * @param {string} styleName
 * @return {string}
 */
function getStyle(element, styleName) {
  if (null === element) return "";
  return getComputedStyle(element).getPropertyValue(styleName);
}

export default getStyle;
