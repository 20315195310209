"use strict";

/**
 * Check if user clicked outside on an element and apply callback function
 *
 * @param {Element} element - HTML object as definition of inside
 * @param {NodeList|Element} exceptions - Collection of elements
 * or single HTMLElement outside that click on won't start callback
 * @param {function} callback - function to apply on click outside
 */
function onClickOutside(element, exceptions, callback) {
  document.addEventListener("click", (event) => {
    const exception = exceptions instanceof NodeList ?
      Array.from(exceptions).find((item) => item === event.target) : exceptions === event.target;
    if (element.contains(event.target) || exception) return;
    if ("function" === typeof callback) callback(event);
  });
}

export default onClickOutside;
