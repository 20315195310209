"use strict";

/**
 * Take a string and parse it to HTML
 *
 * @param {string} str
 * @return {HTMLCollection}
 */
function parseHTML(str) {
  const tmp = document.implementation.createHTMLDocument();
  tmp.body.innerHTML = str;
  return tmp.body.children;
}

export default parseHTML;
