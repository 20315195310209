"use strict";

/**
 * Adds a listener for multiple events to an element
 *
 * @param {Window|HTMLElement} element
 * @param {string|Array<string>} events
 * @param {Function} listener
 */
export default function addMultiListener(element, events, listener) {
  const setListener = (eventName) => element.addEventListener(eventName, listener, false);
  if (Array.isArray(events)) events.forEach((event) => setListener(event));
  if ("string" === typeof events) events.split(" ").forEach((event) => setListener(event));
}
