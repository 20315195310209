"use strict";

/**
 * @param {string} str
 * @return {string}
 */
function capitalizeString(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export default capitalizeString;
