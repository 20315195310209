import { nodeEach, getData } from "../helper/index.js";

/**
 * Creates Bootstrap alert box.
 *
 * @param {string} type - bootstrap class types "success", "danger", etc.
 * @param {string} message - text content of alert
 * @return {HTMLDivElement}
 */
function createAlert(type = "primary", message = "") {
  const alertContainer = document.createElement("div");
  const alertMessage = document.createTextNode(message);

  alertContainer.classList.add("alert", `alert-${type}`);
  alertContainer.appendChild(alertMessage);

  return alertContainer;
}

/**
 * Removes Bootstrap alert, if present.
 *
 * @param {HTMLElement} target
 */
function removeAlert(target) {
  const alert = target.querySelector(".alert");
  if (null !== alert) alert.remove();
}

/**
 * Validates input field.
 *
 * @param {HTMLInputElement|HTMLTextAreaElement} input
 */
function validateInput(input) {
  const valid = input.checkValidity();
  const label = input.closest("label");
  const wrapper = input.closest(".inputWrapper-0-2-373");
  const underLine = input.nextElementSibling;

  input.classList.toggle("is-invalid", !valid);
  label.classList.toggle("is-invalid", !valid);
  wrapper.classList.toggle("invalidIcon-0-2-377", !valid);
  wrapper.classList.toggle("validIcon-0-2-376", valid);
  underLine.classList.toggle("invalidUnderlinePrimary-0-2-371", !valid);
  underLine.classList.toggle("validUnderlinePrimary-0-2-372", valid);
}

/**
 * Validates forms before submit.
 * Email input will be checked beforehand.
 *
 * @param {string} validationClass
 */
export function validateForms(validationClass = ".needs-validation") {
  const forms = document.querySelectorAll(validationClass);

  nodeEach(forms, form => {
    // validate text inputs
    const textInputs = form.querySelectorAll("input[type=\"text\"]");
    nodeEach(textInputs, textInput =>
      textInput.addEventListener("blur", () => validateInput(textInput)));

    // validate textarea
    const textAreas = form.querySelectorAll("textarea");
    nodeEach(textAreas, textArea =>
      textArea.addEventListener("blur", () => validateInput(textArea)));

    // validate email input
    const mailInput = form.querySelector("input[type=\"email\"]");
    mailInput.addEventListener("blur", () => validateInput(mailInput));

    // validate form
    form.addEventListener("submit", event => {
      event.preventDefault();
      event.stopPropagation();

      form.classList.add("was-validated");
      nodeEach(textInputs, textInput => validateInput(textInput));
      nodeEach(textAreas, textArea => validateInput(textArea));
      validateInput(mailInput);

      if (form.checkValidity()) submitForm(form, getData(form, "actionTarget"));
    }, false);
  });
}

/**
 * Submits form data to action target including event handling.
 *
 * @param {HTMLFormElement} form
 * @param {string} actionTarget
 */
export function submitForm(form, actionTarget = "#") {
  const XHR = new XMLHttpRequest();
  const FD = new FormData(form);

  // disable submit button
  const formSubmitBtn = form.querySelector("button[type=\"submit\"]");
  formSubmitBtn.setAttribute("disabled", "");
  formSubmitBtn.classList.add("disabled");

  // remove old error message
  removeAlert(form.parentNode);

  // on success
  XHR.addEventListener("load", () => {
    form.after(createAlert("success", getData(form, "successMessage")));

    // hide form
    form.classList.add("collapse");
  });

  // on error
  XHR.addEventListener("error", event => {
    form.after(createAlert("danger", getData(form, "errorMessage")));
    console.error(event);

    // re-enable submit button
    formSubmitBtn.removeAttribute("disabled");
    formSubmitBtn.classList.remove("disabled");
  });

  // send form data
  XHR.open("POST", actionTarget);
  XHR.send(FD);
}
