"use strict";

const DEFAULT_ID_LENGTH = 8;

/**
 * Generate a random string to identify class instances.
 *
 * @param {number} length - length of the ID
 * @return {string}
 */
function getRandomId(length = DEFAULT_ID_LENGTH) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < length; i++) { id += characters.charAt(Math.floor(Math.random() * characters.length)); }

  return id;
}

export default getRandomId;
