"use strict";

/**
 * Take an element of any type and create a jQuery object
 *
 * @requires jQuery
 * @param {*} element
 * @return {jQuery}
 */
function getJquery(element) {
  return element instanceof jQuery ? element : $(element);
}

export default getJquery;
