"use strict";

/**
 * Return offset of an element relative to document
 *
 * @param {Element} element
 * @return {{top: number, left: number}}
 */
function getOffset(element) {
  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;

  // Return zeros for disconnected and hidden (display: none) elements (gh-2310)
  if (!element.getClientRects().length) return {
    top: 0,
    left: 0,
  };

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
}

export default getOffset;
