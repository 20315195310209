"use strict";

export default class AbstractDetect {
  /**
   * @param {object} detectionObject
   * @param {string} prefix
   */
  static addClassNames(detectionObject, prefix = "is-") {
    const keys = Object.keys(detectionObject);
    const detected = keys.filter((key) => detectionObject[key]);
    detected.map((item) => document.documentElement.classList.add(`${prefix}${item.toLowerCase()}`));
  }
}
