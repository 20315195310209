"use strict";

/**
 * Create an array from an array like object and execute a for each loop callback
 *
 * @param {NodeList|HTMLCollection} list
 * @param {function} callback
 */
function nodeEach(list, callback) {
  // eslint-disable-next-line max-len
  if (!(list instanceof NodeList) && !(list instanceof HTMLCollection)) throw new TypeError(`${list} is not a NodeList or a HTMLCollection.`);
  if ("function" !== typeof callback) throw new TypeError(`${callback} is not a function`);
  Array.from(list).forEach((item, index, array) => callback(item, index, array));
}

export default nodeEach;
