"use strict";

import AbstractDetect from "./AbstractDetect.js";
import capitalizeString from "./../helper/_capitalize-string.function.js";

export default class OSDetect extends AbstractDetect {
  /**
   * @param {Navigator} n
   */
  constructor(n) {
    super();
    this.navigator = n;
    this.systems = {
      Mac: false,
      Windows: false,
      Android: false,
      iOS: false,
      iPhone: false,
      iPad: false,
      mobile: false,
      BlackBerry: false
    };
  }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isMac(n) { return (/Macintosh/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isWindows(n) { return (/Windows/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isAndroid(n) { return (/Android/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isIos(n) { return (/iPhone|iPad|iPod/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isIphone(n) { return (/iPhone/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isIpad(n) { return (/iPad/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isMobile(n) { return (/iPhone|iPad|iPod|Android|BlackBerry/).test(n.userAgent); }

  /**
   * @param {Navigator} n
   * @return {boolean}
   */
  static isBlackberry(n) { return (/BlackBerry/).test(n.userAgent); }

  /**
   * @return {OSDetect}
   */
  init() {
    this.runOSTests();
    return this;
  }

  /**
   * @return {OSDetect}
   */
  runOSTests() {
    const systems = Object.keys(this.systems);

    systems.map((system) => {
      this.systems[system] = OSDetect[`is${capitalizeString(system)}`]
        .call(null, this.navigator);
    });

    return this;
  }

  /**
   * @return {{
   *   iPhone: boolean,
   *   BlackBerry: boolean,
   *   mobile: boolean,
   *   iPad: boolean,
   *   Windows: boolean,
   *   iOS: boolean,
   *   Mac: boolean,
   *   Android: boolean
   * }}
   */
  getResults() { return this.systems; }
}
