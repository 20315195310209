"use strict";

import getBoolAttr from "./_get-bool-attr.function.js";

/**
 * Toggle boolean value of HTML attributes
 *
 * @param {Element} element
 * @param {string} name
 */
function toggleBoolAttr(element, name) {
  if (!element.hasAttribute(name)) return;
  element.setAttribute(name, (!getBoolAttr(element, name)).toString());
}

export default toggleBoolAttr;
