"use strict";

/**
 * Delegate events from document to target selectors
 * Works like jQuery(document).on("click", ".selector", (event) => handler(event, element));
 * http://youmightnotneedjquery.com/#delegate
 *
 * Callback function get the parameters:
 * {Event} event - original event from document
 * {Element} element - event.target
 *
 * @param {string} selector
 * @param {string} eventName
 * @param {function} callback
 */
function delegateEvent(selector, eventName, callback) {
  document.addEventListener(eventName, (event) => {
    for (let target = event.target; target && document !== target; target = target.parentNode) {
      if (!target.matches(selector)) continue;
      if ("function" === typeof callback) callback.call(event.target, event, event.target);
      break;
    }
  }, false);
}

export default delegateEvent;
