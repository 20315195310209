"use strict";

import { debounce } from "throttle-debounce";
import Breakpoints from "../classes/Breakpoints.js";
import { breakpointsMap } from "./_settings.js";

const DEBOUNCE_DELAY = 150;
const bp = new Breakpoints(breakpointsMap).init();

export default function applyBreakpointChangeEvent() {
  let currentBreakpoint = bp.getBreakpointByValue(window.innerWidth);

  /**
   * throw custom event if window resize/orientation change causes breakpoint change
   *
   * to get current breakpoint from jQuery Events:
   * event.originalEvent.detail.currentBreakpoint =>
   * Breakpoint {
   *   name: String,
   *   value: Number
   * }
   */
  window.addEventListener("resize", debounce(DEBOUNCE_DELAY, () => {
    const newBreakpoint = bp.getBreakpointByValue(window.innerWidth);

    if (newBreakpoint.name === currentBreakpoint.name) return;

    const bpcEvent = new CustomEvent("breakpointchange", { detail: {
      breakpoints: bp,
      currentBreakpoint: newBreakpoint
    } });

    currentBreakpoint = newBreakpoint;
    window.dispatchEvent(bpcEvent);
  }, { atBegin: false }));
}
