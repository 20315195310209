"use strict";

import { debounce } from "throttle-debounce";

const DEBOUNCE_DELAY = 150;
const SCROLL_DISTANCE = 0;

export default function dynamicHeader() {
  const headerBottom = document.querySelector(".headerBottom-0-2-48");

  window.addEventListener("scroll", debounce(DEBOUNCE_DELAY, () =>
    headerBottom.classList.toggle("collapsed-0-2-49", SCROLL_DISTANCE < window.scrollY), { atBegin: false }));
}
