import addMultiListener from "./_add-multi-listener.function.js";
import capitalizeString from "./_capitalize-string.function.js";
import deepExtend from "./_deep-extend.function.js";
import delegateEvent from "./_delegate-event.function.js";
import getBoolAttr from "./_get-bool-attr.function.js";
import getData from "./_get-data.function.js";
import getJquery from "./_get-jquery.function.js";
import getOffset from "./_get-offset.function.js";
import getRandomId from "./_get-random-id.function.js";
import getStyle from "./_get-style.function.js";
import nodeEach from "./_node-each.function.js";
import onClickOutside from "./_on-click-outside.function.js";
import onReady from "./_on-ready.function.js";
import parseHTML from "./_parse-html.function.js";
import toggleBoolAttr from "./_toggle-bool-attr.function.js";

// expose some of the helper functions to global window object
export default function exposeHelperToWindow() {
  window.addMultiListener = addMultiListener;
  window.capitalizeString = capitalizeString;
  window.deepExtend = deepExtend;
  window.delegateEvent = delegateEvent;
  window.getData = getData;
  window.getOffset = getOffset;
  window.nodeEach = nodeEach;
  window.onClickOutside = onClickOutside;
  window.onReady = onReady;
  window.parseHTML = parseHTML;
  window.toggleBoolAttr = toggleBoolAttr;
}

export {
  addMultiListener,
  capitalizeString,
  deepExtend,
  delegateEvent,
  getBoolAttr,
  getData,
  getJquery,
  getOffset,
  getRandomId,
  getStyle,
  nodeEach,
  onClickOutside,
  onReady,
  parseHTML,
  toggleBoolAttr,
};
